import React from 'react'

const About = () => {

    const d = new Date();

    const GoWhatsapp = () => {
        window.open('https://wa.link/0rrkmt', '_blank');
    }
    
      const GoInsta = () => {
        window.open('https://instagram.com/rajhansonline', '_blank');
      }
    
      const GoTele = () => {
        window.open('https://t.me/rajhansbook', '_blank');
      }

    return (
        <div className='AboutSec' >


            <div className='AboutImg cp' onClick={GoWhatsapp} ><img src="77.jpeg" alt="" /></div>

            <p>
                <span> Rajhansbook </span>
                is a limited liability company incorporated under the laws of Curacao. Players are requested not to contact any untrusted sources for
                <span> Rajhansbook </span>
                accounts as this is an online site and they can only register with our whatsapp and  deposit through the account details generated by the system or provided by our official support team.
            </p>


            <div className='AboutIcon cp'>
                <div onClick={GoWhatsapp} ><img src="w.svg" alt="" /></div>
                <div onClick={GoInsta}><img src="i.svg" alt="" /></div>
                <div onClick={GoTele}><img src="t.svg" alt="" /></div>
            </div>

            <div className='AboutBtn cp' onClick={GoWhatsapp} >Contact Us</div>

            <div className='AboutIconBm'>
                <div><img src="upi.svg" alt="" /></div>
                <div><img src="bank.svg" alt="" /></div>
            </div>

            <div className='AboutIconBm'>
                <div><img src="3.svg" alt="" /></div>
                <div><img src="4.svg" alt="" /></div>
            </div>

            <div className='AboutIconBm'>
                <div><img src="5.svg" alt="" /></div>
                <div><img src="6.svg" alt="" /></div>
            </div>

            <div className='Copyright'>  © 20018-{d.getFullYear()} Rajhansbook.in - Premium Service.</div>
            <div className='HideenDiv' >.</div>
        </div>
    )
}

export default About