import React from 'react'
import Tele from './Icons/T.png'
import What from './Icons/W.png'
import Insta from './Icons/I.png'

const Foot = () => {
  const GoWhatsapp = () => {
    window.open('https://wa.link/0rrkmt', '_blank');
  }

  const GoInsta = () => {
    window.open('https://instagram.com/rajhansonline', '_blank');
  }

  const GoTele = () => {
    window.open('https://t.me/rajhansbook', '_blank');
  }
  return (
    <div className='Foot'>
      <div className='iconBx' onClick={GoInsta} >
        <div> <img src={Insta} alt='' /> </div>
        <div>Instagram</div>
      </div>
      <div className='iconBx' onClick={GoWhatsapp}>
        <div><img src={What} alt='' /></div>
        <div>Whastapp</div>
      </div>
      <div className='iconBx' onClick={GoTele}>
        <div><img src={Tele} alt='' /></div>
        <div>Telegram</div>
      </div>
    </div>
  )
}

export default Foot